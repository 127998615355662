// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "core/settings";        // color + font variables

body {
  color: $color_text;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

a {
  color: $color_link;
}
a:hover {
  text-decoration: none;
  color: $color_link_hover;
}

// Top Navigation
nav.main {
  background-color: $color_background_dark;
  padding: 15px 0;
  height: 90px;

  color: #fff;

  a.brand {
    color: #fff;

      span {
        font-size: 28px;
        position: relative;
        top: 17px;
        font-weight: 400;
        margin-left: 10px;
        font-style: initial;
      }

  }
}

// Sidebar
section.sidebar {
  padding: 40px 40px 60px 40px;
  border-right: 1px solid $color_border;

  .switcher .btn {
    width: 100%;
    margin-bottom: 30px;
    background-color: $color_link;
    font-weight: 600;
    color: #fff;
  }

  .switcher .dropdown.open .dropdown-menu {
    margin-top: -30px;
    width: 100%;
    text-align: center;
    li {
      border-bottom: 1px solid #F1F1F1;
      a {
        padding: 6px 12px;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  ul {
    li {
      p {
        color: $color_font_heading;
        font-size: $font_size_sidebar_title;
        border-bottom: 1px solid $color_border;
      }

      ul {
        margin: 0;
        li {
          padding-bottom: 3px;
          font-size: $font_size_sidebar_link;
        }

      }
    }
  }


  ul {
    list-style: none;
  }
}

section.sidebar > ul > li {
  margin-bottom: 30px;
}


// Content

article.main {
  padding-top: 26px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;

  p {
    line-height: 1.85em;
    margin: 15px 0 25px
  }

  ol, ul {
    margin-bottom: 25px;
    line-height: 1.85em;
    li {
      padding: 5px 0;
    }
  }

  .breadcrumbs {
    list-style: none;
    margin: 0 0 1.75em 0;
    padding: 0;
    .fa-chevron-left {
      font-size: .8em;
      margin-right: 0.2em;
    }
  }

  // The article's main title
  h1 {
    font-size: $font_size_article_title_h1;
    margin-top: 5px;
    margin-bottom: 40px;
    font-weight: 100;
    color: $color_font_heading;
    border-bottom: 1px solid $color_border;
    padding-bottom:10px;
  }

  // Article's index. ie: table of content
  h1 + ul  ul{
    line-height: 1.1em;
    margin-bottom: 0;
  }
  h1 + ul {
    line-height: 1.1em;
    font-size: $font_size_article_toc;
    list-style-type:  disc;
    margin-bottom: 0px;
    padding-bottom: 40px;
    border-bottom: 1px solid $color_border;
    li {
      font-weight: 600;
      padding-bottom: 6px;
      list-style-type:  disc;

      > ul {
        padding-top: 3px;
        padding-left: 25px;
        li {
          padding-bottom: 3px;
          font-weight: 400;

        }
      }
    }
  }

  // Heading 2
  h2 {
    color: $color_font_docs_heading_h2;
    font-size: $font_size_article_title_h2;
    font-weight: 600;
    margin-top: 60px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  h2 + ul {
    padding-left: 1em;
    margin-top: 1em;
  }

  // Heading 3
//  h2 + h3 {
  h3 {
    color: $color_font_docs_heading_h3;
//    padding: 0 0 20px 0;
    padding: 20px 0 10px 0;
//    color: red;
  }
//  h3 {
//    padding: 20px 0;
//  }

  // Heading 4
  h4 {
    padding: 10px 0 0px 0;
//    color: magenta;
//    color: lighten($color_font_heading, 0%);
//    color: $color_font_heading;
  }

  blockquote {
    color: $color_font_blockquote;
    font-size: inherit;
    background: $color_font_blockquote_background;
    border-radius: 5px;
    margin: 10px 0 20px;
    padding: 5px 15px 1px 15px;
    border: none;

  }

  // Demo blockquote
  blockquote.demo {
    @extend blockquote;
    background: #fff;
    color: lighten($color_text, 20%);
    display: inline-block;
    position:relative;
    padding: 3px 0 0 0;
    p + p {
      margin-top: 16px;
      border-left: 4px solid #FDE3A7;
      padding-left: 36px;
    }

    p {
      padding: 0;
      margin: 0;

    }
    p:first-child {
      a {
        background: #FDE3A7;
        border-radius: 4px;
        padding: 8px 20px 8px 40px;
      }

      a:before {
        color: #D35400;
        position:absolute;
        font-family: FontAwesome;
        top:2px;
        left:12px;
        font-size: 18px;
        content: "\f1e5"; // Binoculars
      }
    }
  }

  // Q&A
  .qna {
    border-top: 1px dashed #ccc;
    .question a {
      display: block;
      padding: 5px 0 8px 0;

    }
    .question i {
      position: relative;
      top: 2px;
      font-size: 20px;
      padding-right: 7px;
    }
    .question i.rotated {
      position: relative;
      top: 6px;
    }

    .answer {
      &.collapse, &.collapsing {
        margin-left: 1.1em;
        //border:1px solid red;
      }

      blockquote.demo p:first-child a:before {
        top: 12px;
        //left: 31px;
      }
      p {
        margin: 10px 5px 20px 0px;
      }
    }

    .answer-footer {
      margin-bottom: 3em;
      text-align: right;
    }
  }

  // Q&A Standalone page H1 heading
  &.qna h1 {
    font-size: $font_size_article_qna_title_h1;
  }

  .qna:last-child {
    border-bottom: 1px dashed #ccc;
  }

  h2 + .qna, ul + .qna {
    margin-top: 25px;
  }
    // Non-TOC list
  p + ul li {
    line-height: 1.85em;
    margin-bottom: .5em;
  }
}

.article-survey-container {
  color: #777;
  line-height: 1.85em;
  .survey-message {

  }
  .survey-button-group, .survey-textarea, .survey-send {
    margin: 0.6em 0;
  }
  #survey-feedback-message {
    width: 100%;
    line-height: normal;
    padding: 0.4em;
    height: 6em;
  }
  .vote-button, .send-button {
    border-radius: 0.3em;
    width: 4.5em;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    background: rgb(231, 233, 236);
    border-radius: 3px;
    border-color: rgb(173, 177, 184) rgb(162, 166, 172) rgb(141, 144, 150);
    border-style: solid;
    border-width: 1px;
    padding: 0px;
    color: $color_text;
    margin-right: 0.6em;
  }

}



.mod-date {
  color: $color_modified_date;
  padding: 0 40px 40px 40px;
}

footer {
  border-top: 1px solid $color_border;
  padding: 40px 20px;
  text-align: center;
  color: $color_footer;
}