
// Colors //

$color_text:            #353535;
$color_border:          #ECECEC;
$color_background_dark: #121212;
$color_font_heading:    #515151;

$color_font_docs_heading_h2:    #222;
$color_font_docs_heading_h3:    lighten($color_font_docs_heading_h2, 5%);

$color_font_blockquote:             #fff;
$color_font_blockquote_background:  #777;

//#F26A00 // Mosets Orange
//#FF7C01
//$color_link:            #008cdd;
$color_link:            #FF7C01;
$color_link_hover:      darken($color_link,20%);

$color_footer:          #999;
$color_modified_date:   #999;

// Fonts //
$font:                  "Source Sans Pro",sans-serif;
$font_size_body_default:          16px; // In px
$font_size_sidebar_title:         1.25em;
$font_size_sidebar_link:          1em;
$font_size_article_title_h1:      2.6em;
$font_size_article_title_h2:      1.75em;
$font_size_article_qna_title_h1:  1.75em;
$font_size_article_toc:           1em;


